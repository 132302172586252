import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import useLang from "../../contexts/Language/LanguageContext";
import { GatsbyImage } from "gatsby-plugin-image";
import { link } from "./Donators.module.scss";

const sortFn = (a, b) => {
  return a.localeCompare(b);
};

export default function Donators() {
  const lang = useLang();
  const donators = useStaticQuery(graphql`
    query {
      allContentfulDonators {
        edges {
          node {
            name
            nameen
            link
            logo {
              title
              description
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <h1>{lang === "mne" ? "Donatori" : "Donators"}</h1>
      {donators.allContentfulDonators.edges
        .filter((edge) => !edge.node.isSponsor)
        .sort((ed1, ed2) => sortFn(ed1.node.name, ed2.node.name))
        .map((edge) => {
          return (
            <div
              key={edge.node.link}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <GatsbyImage
                style={{ maxWidth: "100px" }}
                image={edge.node.logo.gatsbyImageData}
                alt={edge.node.logo.title}
              />
              <a
                className={link}
                href={edge.node.link}
                target="_blank"
                rel="noreferrer"
              >
                {lang === "mne" ? edge.node.name : edge.node.nameen}
              </a>
            </div>
          );
        })}
      {donators.allContentfulDonators.edges.filter(
        (edge) => !!edge.node.isSponsor
      ).length ? (
        <h2>{lang === "mne" ? "Sponzori" : "Sponsors"}</h2>
      ) : null}
      {donators.allContentfulDonators.edges
        .filter((edge) => !!edge.node.isSponsor)
        .sort((ed1, ed2) => sortFn(ed1.node.name, ed2.node.name))
        .map((edge) => {
          return (
            <div
              key={edge.node.link}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <GatsbyImage
                style={{ maxWidth: "100px" }}
                image={edge.node.logo.gatsbyImageData}
                alt={edge.node.logo.title}
              />
              <a
                className={link}
                href={edge.node.link}
                target="_blank"
                rel="noreferrer"
              >
                {lang === "mne" ? edge.node.name : edge.node.nameen}
              </a>
            </div>
          );
        })}
    </>
  );
}
